<template>
  <div class="auth container">
    <img src="/img/paladi.png" class="auth__logo"/>
    <div class="auth__title">Пожалуйста, авторизуйтесь</div>
    <form action="/"
          class="personal-form"
          @submit.prevent="sendRequest"
          ref="form">
      <div class="personal-form__item">
        <div class="personal-form__label">Логин *</div>
        <FormText :pattern="'+7 (###) ###-##-##'" v-model="request.login"/>
      </div>
      <div class="personal-form__item">
        <div class="personal-form__label">Пароль *</div>
        <FormText
          :type="'password'"
          v-model="request.password"
        />
      </div>

      <div class="personal-form__item">
        <button class="btn personal-form__submit" style="margin-bottom: 20px">
          Войти
        </button>
        <router-link to="/registration" class="btn" style="margin-bottom: 30px" replace>
          Регистрация
        </router-link>
        <router-link to="/forgot-password" style="display: flex;justify-content: right;text-decoration: none;color: #ff980c;">
          Забыли пароль ?
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import FormText from "@/components/forms/form-text";

export default {
  components: {
    FormText,
  },

  data() {
    return {
      request: {
        login: null,
        password: null,
      },
      errors: {},
    };
  },

  computed: {
    profile() {
      return this.$store.state.profile;
    },
  },

  methods: {
    sendRequest() {
      this.errors = {};

      const data = this.request;

      this.$store
        .dispatch("AUTH_LOGIN_REQUEST", data)
        .then(() => {
          this.$store.dispatch("FIRST_LOAD_INFORMATION");
          this.$store.dispatch("HOME_FETCH_DATA");

          this.$router.push("/");
          this.$store.commit("AUTH_SET_STATE", {
            key: "isAfterAuth",
            data: true,
          });
        })
        .catch((error) => {
          if (error.response.data.errors)
            this.errors = error.response.data.errors;

          this.$store.commit("SET_MESSAGE_MODAL_DATA", error.response.data);
        });
    },

    getErrorByCode(code) {
      if (Object.prototype.hasOwnProperty.call(this.errors, code))
        return this.errors[code].join(", ");
    },
  },
};
</script>
